import Layout from "components/Layout";

import PrivateOutlet from "components/PrivateOutlet";
import { AnimatePresence } from "framer-motion";
import Assignments from "pages/Assignments";
import Dashboard from "pages/Dashboard";
import Explore from "pages/Explore";
import Insights from "pages/Insights";
import TrainingModules from "pages/TrainingModules";
import Login from "pages/Login";
import StartHere from "pages/StartHere";
import Welcome from "pages/Welcome";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Community from "pages/Community";
import Donations from "pages/Donations";
import CommunityStory from "pages/CommunityStory";
import Rewards from "pages/Rewards";
import HackOne from "pages/Hacks/HackOne";
import DashboardWelcome from "pages/DashboardWelcome";
import HackTwo from "pages/Hacks/HackTwo";
import HackThree from "pages/Hacks/HackThree";
import HackFour from "pages/Hacks/HackFour";
import HackFive from "pages/Hacks/HackFive";
import HackSix from "pages/Hacks/HackSix";
import StudentLogin from "pages/StudentLogin";
import Orientation from "pages/Orientation";
import Chapter from "pages/Orientation/Chapter";
import OrientationEnd from "pages/OrientatioEnd";

const Router = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route element={<Layout />}>
          <Route index element={<Navigate to="/login" />} />
          <Route path="login" element={<Login />} />
          <Route path="student-login" element={<StudentLogin />} />
          <Route path="user" element={<PrivateOutlet />}>
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="welcome" element={<Welcome />} />
            <Route path="dashboard-welcome" element={<DashboardWelcome />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="insights" element={<Insights />} />
            <Route path="explore" element={<Explore />} />

            <Route path="orientation">
              <Route index element={<Orientation />} />
              <Route path=":id" element={<Chapter />} />
            </Route>
            <Route path="orientation-end" element={<OrientationEnd />} />

            <Route path="modules" element={<div>Modules</div>} />
            <Route path="rewards" element={<Rewards />} />
            <Route path="hacks">
              <Route index element={<Navigate to="hack-1" />} />
              <Route path="hack-1" element={<HackOne />} />
              <Route path="hack-2" element={<HackTwo />} />
              <Route path="hack-3" element={<HackThree />} />
              <Route path="hack-4" element={<HackFour />} />
              <Route path="hack-5" element={<HackFive />} />
              <Route path="hack-6" element={<HackSix />} />
            </Route>
            <Route path="training-modules">
              <Route index element={<Navigate to="module-1" />} />
              <Route path=":id" element={<TrainingModules />} />
            </Route>
            <Route path="assignments">
              <Route index element={<Navigate to="first" />} />
              <Route path=":id" element={<Assignments />} />
            </Route>
            <Route path="start-here" element={<StartHere />} />
            <Route path="donations" element={<Donations />} />
            <Route path="community">
              <Route index element={<Community />} />
              <Route path=":id" element={<CommunityStory />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default Router;
