import checks from "assets/images/backgrounds/checks.png";
import StudentWithLaptop from "assets/images/pages/orientation/student-laptop.png";
import CopyReferenceCode from "components/CopyReferenceCode";
import DaysLeft from "components/DaysLeft";
import Profile from "components/Profile";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
};

const Orientation = () => {
  const { profile, setProfile } = useContext(AuthContext);

  const navigate = useNavigate();
  useLayoutConfig("red", true);
  useDocumentTitle("Muskurahat Foundation | Explore");

  return (
    <div>
      <div className="flex m-4">
        <div className="w-1/2">
          <DaysLeft />
        </div>
        <div className="w-1/2">
          <div className="flex justify-end gap-4">
            <CopyReferenceCode />
            <Profile />
          </div>
        </div>
      </div>
      <div className="text-center text-[32px] leading-[35px] font-semibold text-white my-5">
        <h1>WELCOME TO</h1>
        <h1>muskurahat</h1>
      </div>
      <div style={overlayStyle}>
        <div className="text-center py-8">
          <h4 className="text-secondary max-w-[300px] m-auto text-[20px] leading-[30px] font-medium">
            We&apos;ve designed an interactive journey to give you an{" "}
            <span className="text-primary font-semibold">
              in-depth understanding
            </span>{" "}
            about Muskurahat & your internship.
          </h4>
          {profile?.orientation?.begin ? (
            <button
              onClick={() => {
                // get progress from profile
                const progress = profile?.orientation?.progress;
                // get the last chapter completed
                let nextChapter;
                if (progress) {
                  if (Object.keys(progress).length === 9) {
                    navigate("/user/orientation-end");
                    return;
                  }
                  if (Object.keys(progress).length === 10) {
                    nextChapter = "chapter-1";
                  } else
                    nextChapter = `chapter-${Object.keys(progress).length + 1}`;
                } else {
                  nextChapter = "chapter-1";
                }
                // navigate to the last chapter completed
                navigate(`/user/orientation/${nextChapter}`);
              }}
              className="bg-secondary rounded-[9px] margin-auto mt-5 text-white px-5 py-3 text-xl font-bold"
            >
              Resume Your Journey ✨
            </button>
          ) : (
            <button
              onClick={() => {
                setProfile({
                  ...profile,
                  orientation: {
                    begin: { timestamp: new Date() },
                  },
                });
                navigate("/user/orientation/chapter-1");
              }}
              className="bg-secondary rounded-[9px] margin-auto mt-5 text-white px-5 py-3 text-xl font-bold"
            >
              Your Journey Begins Here ✨
            </button>
          )}
          <img
            src={StudentWithLaptop}
            alt="student with laptop"
            className="w-[300px] m-auto mt-24"
          />
        </div>
      </div>
    </div>
  );
};

export default Orientation;
